$(document).on("turbo:load", function () {
  Dropzone.autoDiscover = false;

  // Check if the element exists
  if ($('#my-dropzone').length) {
    initializeDropzone();
  }
});

function initializeDropzone() {
  $dropzoneElement = $('#my-dropzone');
  maxImages = 10;
  relatedId = $('#related_id').val();
  destination = $dropzoneElement.data('destination');

  form_button = $('#with-dropzone');
  form_button.prop('disabled', true);

  var totalFiles = 0; // Track the total number of files
  var completedFiles = 0; // Track the number of completed uploads in the current set

  myGreatDropzone = new Dropzone("#my-dropzone", {
    url: `/images?related_id=${relatedId}&model=${destination}`,
    maxFiles: maxImages,
    resizeWidth: 1024,
    resizeQuality: 1,
    parallelUploads: 2,
    paramName: 'image',
    acceptedFiles: 'image/*',
    addRemoveLinks: true,
    dictRemoveFile: 'Ukloni',
    dictDefaultMessage: "Klikni ovdje za odabir slika!",
    dictCancelUpload: '',
    dictMaxFilesExceeded: `Dopušteni broj slika: ${maxImages} - uklonite višak!`,
    headers: {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
    },
    init: function () {
      this.on('removedfile', function (file) {
        if (file.imageId) {
          $.ajax({
            method: 'DELETE',
            url: `/images/${file.imageId}`,
          });
        }

        // Disable submit button when all files in the current set are removed
        if (this.files.length === 0 && completedFiles === totalFiles) {
          form_button.prop('disabled', true);
        }
      });

      this.on('addedfile', function () {
        totalFiles++; // Increment total files when a file is added
        form_button.prop('disabled', true);
      });

      this.on('success', function (file, response) {
        imageId = response.id;
        file.imageId = imageId;
        completedFiles++; // Increment completed files on success

        // Enable button only when all files in the current set are complete
        if (completedFiles === totalFiles) {
          form_button.prop('disabled', false);
          // Reset counters for the next set of uploads
          totalFiles = 0;
          completedFiles = 0;
        }
      });

      this.on('error', function (file) {
        alert(`Došlo je do greške. Dopušteni broj slika: ${maxImages}! Pokušajte ponovno`);
        this.removeFile(file);
      });
    }
  });
}
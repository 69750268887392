function set_next_date(prev_date, interval, index) {
  // Monthly recurrence
  return new Date(prev_date.getFullYear(), prev_date.getMonth() + index, prev_date.getDate());
}

function convert_to_datetime(date_str) {
  date_arr = date_str.split(".")
  return new Date(date_arr[2], date_arr[1] - 1, date_arr[0])
}

function populate_datepickers(start_date, num_of_installments) {
  $('.dynamic-date').each(function (index) {
    this_date = set_next_date(start_date, 1, index);
    $(this).datepicker('setDate', this_date);
  });
}

function set_installment_counters() {
  $('.installment_count').each(function (i) {
    $(this).text(i + 1);
  });
}

function trigger_nested_fields(number) {
  for (var i = 0; i < number; i++) {
    $(".add_fields").trigger("click");
  }
}

function populate_prices(price) {
  $('.installment_price').number(true, 2, ',', '.');
  $('.installment_price').val(price);
}


function setup_subscriptions() {
  $("#installment-number").on('change', function () {
    $('.installment-field').remove();
    start_date = convert_to_datetime($('.start_subs').val());
    total_amount = $('#subscription_amount').val();
    num_of_installments = $(this).val();
    total_per_installment = total_amount / num_of_installments;

    trigger_nested_fields(num_of_installments);
    set_installment_counters();
    populate_datepickers(start_date, num_of_installments);
    populate_prices(total_per_installment);
  });
}

$(document).on("turbo:load", function () {
  setup_subscriptions()
  set_installment_counters()
});
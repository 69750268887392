$(document).on("turbo:load", function () {
    $('#filter').on('input', function (e) {
        var filter = $(this).val(),
            count = 0;

        $('.filter-wrapper .filter-target').each(function () {
            if ($(this).text().search(new RegExp(filter, "i")) < 0) {
                $(this).parents('.filter-tile').hide();
            } else {
                $(this).parents('.filter-tile').show();
                count++;
            }
        });
    });

    $(function () {
        $("#exercise-source, .exercise-destination").sortable({
            connectWith: ".exercise-destination, #exercise-source",
            handle: '.info-box-content',
            cursor: 'hand',
            stop: function (event, ui) {
                exercise_tile = ui.item
                back_to_source = exercise_tile.parents('#exercise-source').length > 0 ? true : false;

                if (back_to_source) {
                    exercise_tile.find('input.workout_exercise_hash').val('');
                } else {
                    resort_data_on_exercise_inputs(exercise_tile);
                }
                $('.apply-height').animate({ height: $('.source-height').height() -20 + 'px' }, 500);
                return true;
            }
        });
    });

    function resort_data_on_exercise_inputs(exercise_tile) {
        section = exercise_tile.parents('.exercise-destination');
        section_name = section.attr('id');

        $(section).find('.exercise-tile').each(function (index) {
            input_field = $(this).find('input.workout_exercise_hash')
            how_to = $(this).find('textarea.exercise-how-to').val();
            json_obj = {};
            order = index + 1;

            json_obj['exercise_id'] = input_field.data('exercise_id');
            json_obj['workout_exercise_id'] = input_field.data('we_id');
            json_obj['order'] = order;
            json_obj['section_name'] = section_name;
            json_obj['how_to'] = how_to;

            input_field.val(JSON.stringify(json_obj));
        });
    }

    // called after typing and changing textarea
    $('textarea.exercise-how-to').on('change keyup paste', function () {
        tile = $(this).parents('.exercise-tile');
        resort_data_on_exercise_inputs(tile);
    });


    function iterate_on_edit() {
        $('.exercise-destination').find('.exercise-tile').each(function (index) {
            resort_data_on_exercise_inputs($(this));
        });
    }

    // called on page load
    iterate_on_edit();

});
